import { CompanyGuid } from '@breezy/shared'
import {
  faScrewdriverWrench,
  faTruckFast,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Skeleton } from 'antd'
import classNames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { HeroIconWithTitleAndMessage } from '../../adam-components/HeroIconWithTitleAndMessage/HeroIconWithTitleAndMessage'
import { OnsitePageSection } from '../../adam-components/OnsitePage/OnsitePageSection'
import { useOnsitePageDimensions } from '../../adam-components/OnsitePage/onsitePageUtils'
import { SmartAccountExperienceCompanyInfoBox } from '../../components/AccountExperienceCompanyInfoBox/SmartAccountExperienceCompanyInfoBox'
import { SmartAccountExperienceCompanyInfoSection } from '../../components/AccountExperienceCompanyInfoBox/SmartAccountExperienceCompanyInfoSection'
import { BehindFeatureFlag } from '../../components/BehindFeatureFlag'
import { Banner } from '../../elements/Banner/Banner'
import { HtmlRenderer } from '../../elements/HtmlRenderer/HtmlRenderer'
import {
  useIsLargeScreen,
  useIsMobile,
  useIsSmallScreen,
} from '../../hooks/useIsMobile'
import { UnauthPricebookPhotosEnabledProvider } from '../../hooks/useIsPricebookPhotosEnabled'
import { GooglePlacesWrapper } from '../../providers/GoogleMapsPlaces'
import { useStrictContext } from '../../utils/react-utils'
import { AccountExperienceJobRequestPage } from '../AccountExperienceJobRequestPage/AccountExperienceJobRequestPage'
import { OnlineBookingForm } from './OnlineBookingForm'
import {
  OnlineBookingCompanyConfigContext,
  WithOnlineBookingCompanyConfigContext,
  WithOnlineBookingContexts,
} from './onlineBookingUtils'

export const AccountExperienceBookNowPage = React.memo(() => {
  return (
    <BehindFeatureFlag
      enabledFeatureFlag="instant-booking"
      render={<AccountExperienceOnlineBookingPage />}
      fallback={<AccountExperienceJobRequestPage />}
    />
  )
})

export const AccountExperienceOnlineBookingPage = React.memo(() => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const companyGuid = queryParams.get('companyGuid')
  if (!companyGuid) {
    return <div>No companyGuid provided</div>
  }
  return <AccountExperienceJobRequestPageInner companyGuid={companyGuid} />
})

const AccountExperienceJobRequestPageInner = React.memo(
  ({ companyGuid }: { companyGuid: string }) => {
    const isMobile = useIsMobile()
    const isSmallScreen = useIsSmallScreen()
    const isLargeScreen = useIsLargeScreen()
    const pageDimensions = useOnsitePageDimensions()

    const [step, setStep] = useState<'pre-submit' | 'submitted'>('pre-submit')

    const paddingClassName = useMemo(() => {
      if (isMobile) {
        return 'py-10'
      }
      if (isSmallScreen) {
        return 'py-16'
      }
      return 'p-16'
    }, [isMobile, isSmallScreen])

    const onSubmitted = useCallback(() => {
      setStep('submitted')
    }, [setStep])

    return (
      <UnauthPricebookPhotosEnabledProvider companyGuid={companyGuid}>
        <GooglePlacesWrapper>
          <div
            className={classNames(
              'flex flex-row items-start justify-center',
              paddingClassName,
            )}
          >
            {isLargeScreen && (
              <SmartAccountExperienceCompanyInfoBox companyGuid={companyGuid} />
            )}
            <div
              style={pageDimensions.style}
              // The estimates always have this padding on the top and bottom because the estimate sections do it that
              // way. It's accounted for everywhere, but here it makes the top of the first section not align with the
              // company info box.
              className={isMobile ? 'my-[-16px]' : 'my-[-24px]'}
            >
              <OnsitePageSection padding="large-mobile">
                {step === 'pre-submit' && (
                  <HeroIconWithTitleAndMessage
                    title="Online booking"
                    message="Please fill out the form below. Providing detailed information will help us deliver the best possible service."
                    faIcon={faScrewdriverWrench}
                    color="gray"
                  />
                )}

                {step === 'submitted' && (
                  <OnlineBookingSubmittedPage companyGuid={companyGuid} />
                )}
              </OnsitePageSection>
              {step === 'pre-submit' && (
                <WithOnlineBookingContexts
                  companyGuid={companyGuid}
                  loadingComponent={<OnlineBookingFormSkeleton />}
                >
                  <OnlineBookingForm
                    companyGuid={companyGuid}
                    onSubmit={onSubmitted}
                  />
                </WithOnlineBookingContexts>
              )}
              {!isLargeScreen && (
                <SmartAccountExperienceCompanyInfoSection
                  companyGuid={companyGuid}
                />
              )}
            </div>
          </div>
        </GooglePlacesWrapper>
      </UnauthPricebookPhotosEnabledProvider>
    )
  },
)

type OnlineBookingSubmittedPageProps = {
  companyGuid: CompanyGuid
}
const OnlineBookingSubmittedPage = React.memo(
  ({ companyGuid }: OnlineBookingSubmittedPageProps) => {
    return (
      <>
        <HeroIconWithTitleAndMessage
          title="Request submitted"
          message="Thank you for your request! We’ve received your details and will contact you shortly to confirm your appointment and provide any additional information."
        />
        <WithOnlineBookingCompanyConfigContext
          companyGuid={companyGuid}
          loadingComponent={<OnlineBookingFormSkeleton rows={2} />}
        >
          <AfterHoursBanner />
        </WithOnlineBookingCompanyConfigContext>
      </>
    )
  },
)

const AfterHoursBanner = React.memo(() => {
  const { afterHoursBannerHtml } = useStrictContext(
    OnlineBookingCompanyConfigContext,
  )

  if (!afterHoursBannerHtml) {
    return null
  }

  return (
    <div className="pt-2">
      <Banner>
        <div className="flex items-center gap-3">
          <div>
            <div
              className={classNames(
                'mx-auto flex h-[32px] w-[32px] items-center justify-center rounded-full bg-bz-orange-200',
              )}
            >
              <FontAwesomeIcon
                className="text-bz-orange-800"
                icon={faTruckFast}
              />
            </div>
          </div>
          <HtmlRenderer
            // This will remove the bottom margin from the paragraph element in the HTML
            className="*:last:mb-0"
            htmlContent={afterHoursBannerHtml}
          />
        </div>
      </Banner>
    </div>
  )
})

type OnlineBookingFormSkeletonProps = {
  rows?: number
}
const OnlineBookingFormSkeleton = React.memo(
  ({ rows = 6 }: OnlineBookingFormSkeletonProps) => {
    return (
      <div className="p-4">
        <Skeleton paragraph={{ rows }} />
      </div>
    )
  },
)
