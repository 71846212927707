import { CompanyGuid, EquipmentType, TimeZoneId } from '@breezy/shared'
import React from 'react'

import { useQuery } from 'urql'
import { DocumentType, gql } from '../../generated'
import GqlQueryLoader from '../GqlQueryLoader/GqlQueryLoader'

export type AccountExperienceCompanyInfo = {
  tzId: TimeZoneId | undefined
  hiddenEquipmentTypes: EquipmentType[]
}

type WithAccountExperienceCompanyInfoProps = {
  companyGuid: CompanyGuid
  loadingComponent?: JSX.Element
  render: (companyInfo: AccountExperienceCompanyInfo) => JSX.Element
}

const ACCOUNT_EXPERIENCE_COMPANY_INFO_QUERY = gql(/* GraphQL */ `
  query AccountExperienceFetchCompanyInfo($companyGuid: uuid!) {
    companiesByPk(companyGuid: $companyGuid) {
      timezone
      companyConfig {
        hiddenEquipmentTypesJsonArray
      }
    }
  }
`)

type AccountExperienceCompanyInfoQuery = DocumentType<
  typeof ACCOUNT_EXPERIENCE_COMPANY_INFO_QUERY
>

const convertQueryToAccountExperienceCompanyInfo = (
  data: AccountExperienceCompanyInfoQuery,
): AccountExperienceCompanyInfo => {
  return {
    tzId: data?.companiesByPk?.timezone,
    hiddenEquipmentTypes:
      data?.companiesByPk?.companyConfig?.hiddenEquipmentTypesJsonArray ?? [],
  }
}

const useFetchAccountExperienceCompanyInfo = (companyGuid: CompanyGuid) => {
  const companyInfoQuery = useQuery({
    query: ACCOUNT_EXPERIENCE_COMPANY_INFO_QUERY,
    variables: {
      companyGuid,
    },
  })

  return companyInfoQuery
}

export const WithAccountExperienceCompanyInfo =
  React.memo<WithAccountExperienceCompanyInfoProps>(
    ({ companyGuid, render, loadingComponent }) => {
      const companyInfoQuery = useFetchAccountExperienceCompanyInfo(companyGuid)

      return (
        <GqlQueryLoader
          query={companyInfoQuery}
          loadingComponent={loadingComponent}
          idleComponent={loadingComponent}
          render={data => {
            return render(convertQueryToAccountExperienceCompanyInfo(data))
          }}
        />
      )
    },
  )
