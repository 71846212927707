import React from 'react'
import { useFormContext } from 'react-hook-form'
import { DateField } from '../../elements/Forms/DateField'
import { OptionalDateField } from '../../elements/Forms/OptionalDateField'
import { ReactHookFormItem } from '../../elements/Forms/ReactHookFormItem'
import { SelectField } from '../../elements/Forms/SelectField'
import { AvailabilitySubFormData } from './onlineBookingUtils'

const TIME_WINDOWS = [
  {
    label: '8 AM - 11 AM',
    value: '8 AM - 11 AM',
  },
  {
    label: '11 AM - 2 PM',
    value: '11 AM - 2 PM',
  },
  {
    label: '2 PM - 5 PM',
    value: '2 PM - 5 PM',
  },
]

export const OnlineBookingAvailabilitySubForm = React.memo(() => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext<AvailabilitySubFormData>()

  const preferredAvailabilityDate = watch('preferredAvailabilityDate')
  const backupAvailabilityDate = watch('backupAvailabilityDate')

  const preferredAvailabilityTimeWindows = watch(
    'preferredAvailabilityTimeWindows',
  )
  const backupAvailabilityTimeWindows = watch('backupAvailabilityTimeWindows')

  return (
    <div className="grid grid-cols-2 items-center gap-x-3 gap-y-6 md:grid-cols-5">
      <ReactHookFormItem
        control={control}
        outerClassName="col-span-2"
        name="preferredAvailabilityDate"
        label="Preferred Date #1"
        required
        noBottomMargin
        errors={errors}
        render={({ field }) => (
          <DateField
            disabledDate={current => {
              if (!current) return false

              const today = new Date()
              today.setHours(0, 0, 0, 0)
              const isSameDateAsBackup =
                current.toISOString().split('T')[0] === backupAvailabilityDate

              const isDateInThePast = current < today

              return isDateInThePast || isSameDateAsBackup
            }}
            size="large"
            {...field}
          />
        )}
      />
      <ReactHookFormItem
        control={control}
        outerClassName="col-span-2 md:col-span-3"
        name="preferredAvailabilityTimeWindows"
        label="Preferred Time of Day #1"
        required
        noBottomMargin
        errors={errors}
        render={({ field }) => {
          return (
            <SelectField
              mode="multiple"
              options={TIME_WINDOWS}
              {...field}
              size="large"
              sheetSize="half"
              title="Type of Service"
              values={preferredAvailabilityTimeWindows ?? []}
            />
          )
        }}
      />
      <ReactHookFormItem
        control={control}
        outerClassName="col-span-2"
        name="backupAvailabilityDate"
        label="Preferred Date #2"
        noBottomMargin
        errors={errors}
        render={({ field }) => (
          <OptionalDateField
            disabledDate={current => {
              if (!current) return false

              const today = new Date()
              today.setHours(0, 0, 0, 0)
              const isSameDateAsPreferred =
                current.toISOString().split('T')[0] ===
                preferredAvailabilityDate

              const isDateInThePast = current < today

              return isDateInThePast || isSameDateAsPreferred
            }}
            size="large"
            {...field}
          />
        )}
      />
      <ReactHookFormItem
        control={control}
        outerClassName="col-span-2 md:col-span-3"
        name="backupAvailabilityTimeWindows"
        noBottomMargin
        label="Preferred Time of Day #2"
        errors={errors}
        render={({ field }) => {
          return (
            <SelectField
              mode="multiple"
              options={TIME_WINDOWS}
              {...field}
              size="large"
              sheetSize="half"
              title="Type of Service"
              values={backupAvailabilityTimeWindows ?? []}
            />
          )
        }}
      />
    </div>
  )
})
