import {
  fuzzyMatch,
  isNullish,
  ONLINE_BOOKING_SERVICE_TYPES,
  OnlineBookingServiceTypeDescriptions,
  OnlineBookingServiceTypeJobTypeLabels,
} from '@breezy/shared'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { ReactHookFormItem } from '../../elements/Forms/ReactHookFormItem'
import { SelectField } from '../../elements/Forms/SelectField'
import { TextAreaField } from '../../elements/Forms/TextAreaField'
import { ToggleButtonGroupField } from '../../elements/Forms/ToggleButtonGroupField'
import {
  RequestDetailsSubFormData,
  useBookableJobTypes,
} from './onlineBookingUtils'

export const OnlineBookingRequestDetailsSubForm = React.memo(() => {
  const {
    control,
    formState: { errors },
    watch,
    setValue,
  } = useFormContext<RequestDetailsSubFormData>()

  const requestType = watch('requestType')

  const bookableJobTypes = useBookableJobTypes(requestType)

  return (
    <div className="grid grid-cols-1 gap-y-6">
      <ReactHookFormItem
        control={control}
        name="requestType"
        label="Which option best describes your request?"
        noBottomMargin
        errors={errors}
        required
        render={({ field }) => {
          return (
            <SelectField
              options={ONLINE_BOOKING_SERVICE_TYPES.map(rt => {
                return {
                  value: rt,
                  label: OnlineBookingServiceTypeDescriptions[rt],
                }
              })}
              {...field}
              size="large"
              showSearch
              sheetSize="half"
              filterOption={(inputValue, option) => {
                return !![option?.children, option?.value, option?.label]
                  .filter(Boolean)
                  .map(v => `${v}`)
                  .find(v => fuzzyMatch(v, inputValue))
              }}
              onChange={value => {
                field.onChange(value)
                setValue('jobTypeGuid', '')
              }}
              title="Which option best describes your request?"
            />
          )
        }}
      />

      {!isNullish(requestType) && bookableJobTypes.length > 0 && (
        <ReactHookFormItem
          control={control}
          name="jobTypeGuid"
          errors={errors}
          noBottomMargin
          required
          label={OnlineBookingServiceTypeJobTypeLabels[requestType]}
          render={({ field }) => {
            return (
              <ToggleButtonGroupField
                mode="single"
                options={bookableJobTypes.map(j => {
                  return { value: j.jobTypeGuid, label: j.name }
                })}
                {...field}
              />
            )
          }}
        />
      )}

      <ReactHookFormItem
        control={control}
        name="requestDetails"
        noBottomMargin
        label="Please provide more details regarding your request"
        required
        errors={errors}
        render={({ field }) => <TextAreaField size="large" {...field} />}
      />
    </div>
  )
})
